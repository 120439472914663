<template>
  <v-row align="center" justify="center" class="">
    <v-col cols="12">
      <div class="fn24-weight7 text-center" style="color: #1a347f">
        Online Consulting
      </div>
    </v-col>
    <v-card
      width="458"
      height="110"
      class="mx-auto"
      style="
        background: linear-gradient(268.1deg, #d5e4ff 7.54%, #c1d1ff 100.72%);
      "
    >
      <v-row>
        <v-col cols="4" class="pa-0">
          <!-- <img src="@/assets/profile/no1.png" class="my-7 mx-6" /> -->
          <img :src="doctorImage" class="my-7 mx-6" />
        </v-col>
        <v-col cols="6" class="px-0 my-6 ml-4">
          <div class="fn16-weight7 mb-2" style="color: #1a347f">
            {{ doctorName }}
          </div>
          <div class="fn14">{{ avaliableTime }}</div>
        </v-col>
      </v-row>
    </v-card>
    <v-col cols="12" class="pa-0">
      <v-form>
        <v-stepper v-model="stepper" elevation="0">
          <v-stepper-items>
            <v-stepper-content step="1" class="px-0 mb-2">
              <div class="fn16-weight7 text-center mb-2" style="color: #1a347f">
                Select a Date
              </div>
              <v-divider class="my-4"></v-divider>
              <v-row align="center" justify="center">
                <v-col cols="12" md="8">
                  <v-date-picker
                    v-model="date"
                    width="360"
                    class="mb-4"
                    color="#07204B"
                    full-width
                  ></v-date-picker>
                </v-col>
              </v-row>
              <div class="text-center">
                <v-btn
                  width="360"
                  height="57"
                  dark
                  elevation="0"
                  class="rounded-lg text-capitalize buttoncolor"
                  @click="stepper = 2"
                >
                  Next
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2" class="px-0 mb-10">
              <div class="fn16-weight7 text-center mb-2" style="color: #1a347f">
                Select Time
              </div>
              <v-divider class="my-4"></v-divider>
              <div class="text-center" style="color: #1a347f">
                <span class="mr-14 fn16-weight7">{{ date }}</span>
                <span class="ml-16 fn14" @click="stepper -= 1"
                  ><a-icon type="left" /> Change Date</span
                >
              </div>
              <v-divider class="my-4"></v-divider>
              <v-row>
                <v-col
                  cols="6"
                  md="3"
                  v-for="(choice, index) in dateChoices"
                  :key="index"
                >
                  <v-btn
                    :ref="'b' + index"
                    elevation="0"
                    class="
                      rounded-pill
                      fn20
                      text-center
                      my-4
                      mx-1
                      d-inline-block
                    "
                    style="width: 175px; height: 44px; background: #ededed"
                    @click="selectDate(choice, index)"
                  >
                    <div class="my-1">{{ choice.time }}</div>
                  </v-btn>
                </v-col>
              </v-row>
              <div class="text-center">
                <v-btn
                  width="360"
                  height="57"
                  dark
                  elevation="0"
                  class="rounded-lg text-capitalize buttoncolor"
                  @click="next()"
                >
                  Next
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3" class="px-0 mb-5">
              <div class="ma-4" style="color: #1a347f">
                <span class="mr-14 fn16-weight7"
                  >{{ date }}, {{ time.time }}</span
                >
              </div>
              <v-divider class="my-4"></v-divider>
              <v-row class="ma-4">
                <!-- <v-col cols="12">
                  <div class="fn16" style="color: #1a347f">Your Topic*:</div>
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="fn16" style="color: #1a347f">
                    <v-autocomplete
                      dense
                      outlined
                      hide-details
                      :items="['Symptoms consulting']"
                    />
                  </div>
                </v-col> -->
                <v-col cols="12">
                  <div class="fn16" style="color: #1a347f">Your Title*:</div>
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="fn16" style="color: #1a347f">
                    <v-text-field
                      dense
                      outlined
                      hide-details
                      placeholder="type your title here"
                    />
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="fn16" style="color: #1a347f">Description:</div>
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="fn16" style="color: #1a347f">
                    <v-textarea
                      dense
                      outlined
                      hide-details
                      rows="3"
                      placeholder="type your title here"
                    />
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="fn16" style="color: #1a347f">
                    Attach images or videos:
                  </div>
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="mb-2">
                    <img src="@/assets/icon/upload.png" />
                    <span class="ml-2 fn16-weight7" style="color: #1a347f"
                      >Upload files</span
                    >
                  </div>
                  <vue-upload-multiple-image
                    @upload-success="uploadImageSuccess"
                    @before-remove="beforeRemove"
                    @edit-image="editImage"
                    :data-images="showImage"
                    dragText="ลากไฟล์มาที่นี่"
                    browseText="เลือกไฟล์"
                    primaryText="รูปภาพเริ่มต้น"
                  ></vue-upload-multiple-image>
                </v-col>
              </v-row>
              <div class="text-center">
                <v-btn
                  width="360"
                  height="57"
                  dark
                  elevation="0"
                  class="rounded-lg text-capitalize buttoncolor"
                  @click="submit()"
                >
                  Confirm my appointment
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <v-dialog v-model="opendialog" :overlay-opacity="0.9" max-width="380">
          <!-- <a-icon
            type="close"
            class="white--text text-right"
            @click="opendialog = false"
          /> -->
          <v-card
            class="pt-0 pb-2"
            max-width="375"
            min-height="378"
            max-height="378"
          >
            <div class="fn24-weight7 text-center pt-8" style="color: #1a347f">
              Confirm your appointment
            </div>
            <div>
              <img
                :src="doctorImage"
                class="my-4"
                style="display: block; margin-left: auto; margin-right: auto"
              />
              <!-- <img
                src="@/assets/profile/no1.png"
                class="my-4"
                style="display: block; margin-left: auto; margin-right: auto"
              /> -->
              <div class="fn16-weight7 text-center" style="color: #1a347f">
                {{ doctorName }}
              </div>
              <v-divider class="my-4"></v-divider>
              <v-row no-gutters class="pa-0">
                <v-col cols="6" class="px-0 text-center">
                  <span class="fn16-weight7">{{ date }}</span>
                </v-col>
                <v-col cols="6" class="px-0 text-center">
                  <span class="fn16-weight7">{{ time.time }}</span>
                </v-col>
              </v-row>
              <v-divider class="my-4"></v-divider>
            </div>
            <v-card-actions>
              <v-btn
                width="300"
                height="57"
                dark
                elevation="0"
                class="rounded-lg text-capitalize buttoncolor"
                block
                @click="
                  () => {
                    (opendialog = false), (dialogView = true);
                  }
                "
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>

      <v-dialog
        v-model="dialogView"
        persistent
        :overlay-opacity="0.9"
        max-width="380"
      >
        <!-- <a-icon type="close" class="text-right white--text" @click="cancel" /> -->
        <v-card
          class="pt-8 pb-2"
          max-width="375"
          min-height="451"
          max-height="451"
        >
          <div class="fn24-weight7 text-center" style="color: #1a347f">
            Your appointment has been confirmed!
          </div>
          <div>
            <img
              :src="doctorImage"
              class="my-2"
              style="display: block; margin-left: auto; margin-right: auto"
            />
            <!-- <img
              src="@/assets/profile/no1.png"
              class="my-2"
              style="display: block; margin-left: auto; margin-right: auto"
            /> -->
            <div class="fn16-weight7 text-center" style="color: #1a347f">
              {{doctorName}}
            </div>
            <v-divider class="my-4"></v-divider>
            <v-row no-gutters>
              <v-col cols="6" class="text-center">
                <span class="fn16-weight7">{{ date }}</span>
              </v-col>
              <v-col cols="6" class="text-center">
                <span class="fn16-weight7">{{ time.time }}</span>
              </v-col>
            </v-row>
            <v-divider class="my-4"></v-divider>
            <div class="mx-6 fn12-weight7">
              You can preview your appointment in ‘My Appointments’ and click
              the Zoom link before 15 mins of event time.
            </div>
          </div>
          <v-card-actions>
            <v-btn
              width="300"
              height="57"
              dark
              elevation="0"
              class="rounded-lg text-capitalize buttoncolor"
              block
              @click="goToAppointments"
            >
              View My Appointments
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
import VueUploadMultipleImage from "vue-upload-multiple-image";
export default {
  components: {
    VueUploadMultipleImage,
  },
  data() {
    return {
      stepper: 1,
      clicked: false,
      time: "",
      selectedTime: "",
      date: new Date().toISOString().substr(0, 10),
      dateChoices: [
        { time: "09:00 - 10:00" },
        { time: "10:00 - 11:00" },
        { time: "12:00 - 13:00" },
        { time: "13:00 - 14:00" },
        { time: "14:00 - 15:00" },
        { time: "15:00 - 16:00" },
        { time: "16:00 - 17:00" },
        { time: "17:00 - 18:00" },
      ],
      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: [],
      imageName1: "",
      opendialog: false,
      dialogView: false,

      id: "",
      typeName: "",
      typeDescription: "",
      activeFlag: true,
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      doctorName: "",
      doctorImage: "",
      avaliableTime: "",
    };
  },
  created() {
    var Typedata = JSON.parse(Decode.decode(localStorage.getItem("Typedata")));
    console.log("Typedata", Typedata);
    this.id = Typedata.id;
    this.typeName = Typedata.type;
    this.typeDescription = Typedata.description;
    this.activeFlag = Typedata.activeFlag;
    this.doctorName = Typedata.name;
    this.doctorImage = Typedata.image;
    this.avaliableTime = Typedata.available;
    // available: "Available every Wed-Sat"
    // image: "/img/no2.733116ef.png"
    // name: "Dr.Emma Watson"
    // status: ""
  },
  methods: {
    selectDate(value, index) {
      if (this.selectedTime) {
        this.$refs[this.selectedTime][0].$el.classList.remove("btn-active");
      }
      const name = "b" + index;
      this.selectedTime = name;

      this.clicked = !this.clicked;
      this.time = value;
      console.log(this.$refs[name][0]);
      this.$refs[name][0].$el.classList.add("btn-active");
    },
    uploadImageSuccess(formData, index, fileList) {
      console.log("data", formData, index, fileList);
      // this.showImage = fileList;
      // Upload image api
      // axios.post('http://your-url-upload', formData).then(response => {
      //   console.log(response)
      // })
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      var r = confirm("remove image");
      if (r == true) {
        done();
      } else {
      }
    },
    editImage(formData, index, fileList) {
      console.log("edit data", formData, index, fileList);
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    goToAppointments() {
      this.$router.push("ManageAppointments");
    },
    cancel() {
      this.dialogView = false;
      this.$router.push("ManageConsulting");
    },
    async next() {
      console.log("this.stepper", this.stepper);

      if (this.stepper === 2) {
        if (this.selectedTime) {
          this.stepper += 1;
        } else {
          this.$swal.fire({
            icon: "error",
            text: `กรุณาเลือกเวลา`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    submit() {
      if (this.stepper === 3) {
        this.opendialog = true;
        // if (this.$refs.createForm.validate(true)) {
        //   var user = JSON.parse(
        //     Decode.decode(localStorage.getItem("userBiData"))
        //   );
        //   const auth = {
        //     headers: {
        //       Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
        //     },
        //   };
        //   const data = {
        //     id: this.id,
        //     type: this.typeName,
        //     description: this.typeDescription,
        //     status: this.typeStatus,
        //   };
        //   const response = await this.axios.put(
        //     `${process.env.VUE_APP_API}/types/` + this.id,
        //     data,
        //     auth
        //   );
        //   console.log("createType", response);
        //   console.log(response.data.response_status);
        //   if (response.data.response_status == "SUCCESS") {
        //     this.$swal.fire({
        //       icon: "success",
        //       text: `สร้างประเภทสินค้าสำเร็จ`,
        //       showConfirmButton: false,
        //       timer: 1500,
        //     });
        //     this.$router.push("ManageType");
        //   } else {
        //     this.$swal.fire({
        //       icon: "error",
        //       text: `สร้างประเภทสินค้าไม่สำเร็จ`,
        //       showConfirmButton: false,
        //       timer: 1500,
        //     });
        //   }
        // }
      }
    },
  },
};
</script>

<style scoped>
.buttoncolor {
  background: linear-gradient(268.1deg, #07204b 7.54%, #1a347f 100.72%);
}

.btn-active {
  width: 175px;
  height: 44px;
  background: linear-gradient(
    268.1deg,
    #d5e4ff 7.54%,
    #c1d1ff 100.72%
  ) !important;
  border: 3px solid #1a347f;
}
</style>
